import { useEffect, useState } from "react";
import { Routes, Route, Link } from "react-router-dom";

import styled from "styled-components";
import Countdown from "react-countdown";
import { Button, CircularProgress, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import gif from "./images/testNew.gif";
import logo from "./images/raddadsLogo.png";
import gek from "./images/33.png";
import ad from "./images/34.png";

import { FaTwitter } from 'react-icons/fa';






import * as anchor from "@project-serum/anchor";

import { LAMPORTS_PER_SOL } from "@solana/web3.js";

import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";

import {
  CandyMachine,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
  shortenAddress,
} from "./candy-machine";

const ConnectButton = styled(WalletDialogButton)`
  color: white;
  background-color: #272626;
  font-weight: bold;

  &:hover {
    color: white;
    background-color: teal;
    font-weight: bold;
  }
`;

const CounterText = styled.span``; // add your styles here

const MintContainer = styled.div``; // add your styles here

const MintButton = styled(Button)`
  color:rgb(69, 34, 181);
  background-color: white;
  font-weight: bold;
  &:hover {
    color: white;
    background-color: teal;
    font-weight: bold;
  }
`; // add your styles here

export interface HomeProps {
  candyMachineId: anchor.web3.PublicKey;
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
}

const Home = (props: HomeProps) => {
  const [balance, setBalance] = useState<number>();
  const [isActive, setIsActive] = useState(false); // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT

  const [itemsAvailable, setItemsAvailable] = useState(0);
  const [itemsRedeemed, setItemsRedeemed] = useState(0);
  const [itemsRemaining, setItemsRemaining] = useState(0);

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const [startDate, setStartDate] = useState(new Date(props.startDate));

  const wallet = useAnchorWallet();
  const [candyMachine, setCandyMachine] = useState<CandyMachine>();

  const refreshCandyMachineState = () => {
    (async () => {
      if (!wallet) return;

      const {
        candyMachine,
        goLiveDate,
        itemsAvailable,
        itemsRemaining,
        itemsRedeemed,
      } = await getCandyMachineState(
        wallet as anchor.Wallet,
        props.candyMachineId,
        props.connection
      );

      setItemsAvailable(itemsAvailable);
      setItemsRemaining(itemsRemaining);
      setItemsRedeemed(itemsRedeemed);

      setIsSoldOut(itemsRemaining === 0);
      setStartDate(goLiveDate);
      setCandyMachine(candyMachine);
    })();
  };

  const onMint = async () => {
    try {
      setIsMinting(true);
      if (wallet && candyMachine?.program) {
        const mintTxId = await mintOneToken(
          candyMachine,
          props.config,
          wallet.publicKey,
          props.treasury
        );

        const status = await awaitTransactionSignatureConfirmation(
          mintTxId,
          props.txTimeout,
          props.connection,
          "singleGossip",
          false
        );

        if (!status?.err) {
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success",
          });
        } else {
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          });
        }
      }
    } catch (error: any) {
      // TODO: blech:
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
    } finally {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
      setIsMinting(false);
      refreshCandyMachineState();
    }
  };

  useEffect(() => {
    (async () => {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet, props.connection]);

  useEffect(refreshCandyMachineState, [
    wallet,
    props.candyMachineId,
    props.connection,
  ]);

  return (
    <main className="main-container">
      <div className="top">
          <div>
            {wallet ? (
              <div className="wallet-info-container">
              <div className="wallet-info">
                <text><b>Your Wallet:</b> {shortenAddress(wallet.publicKey.toBase58() || "")}</text>
                <text><b>Sol Available:</b> {(balance || 0).toLocaleString()}◎</text>
              </div>
              </div>
            ) : (
              <ConnectButton>Connect Wallet</ConnectButton>
            )
            }
          </div>
          <div className="menu-items">
            <div className="menu-item">
              <Link to="/" className="menu-item-text">Home</Link>
            </div>
            <div className="menu-item">
              <Link to="/howto" className="menu-item-text">How to Mint</Link>
            </div>
            <div className="menu-item">
              <Link to="/about" className="menu-item-text">About/Roadmap</Link>
            </div>
          </div>
      </div>

      <div className="main-section-team">

        <div className="images-team">
          <div className="logo" >
            <img src={gek} alt="gek avatar" className="profile"/>

            <div className="team-section">
              <div className="team-deets">
                <text className="team-name">GEK</text>
                <text>Code, art and Dad jokes</text>
                <div className="twitter-line">
                  <FaTwitter className="twitter-item"/>
                  <a href={'https://www.twitter.com/nevertrades'} className="twitter item">@nevertrades</a>
                </div>
              </div>

            </div>

          </div>

          <div className="logo" >
            <img src={ad} alt="AD avatar" className="profile"/>

            <div className="team-section">
              <div className="team-deets">
                <text className="team-name">AD</text>
                <text>Discord liason</text>
                <div className="twitter-line">
                  <FaTwitter className="twitter-item"/>
                  <a href={'https://www.twitter.com/antdke'} className="twitter item">@antdke</a>
                </div>
              </div>

            </div>

          </div>

        </div>

        <div className="minting-deets">




          {/*wallet && <p>Redeemed: {itemsRedeemed}</p>}

      {wallet && <p>Remaining: {itemsRemaining}</p>*/}


        </div>
      </div>
    </main>
  );
};

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}

const renderCounter = ({ days, hours, minutes, seconds, completed }: any) => {
  return (
    <CounterText>
      {hours + (days || 0) * 24} hours, {minutes} minutes, {seconds} seconds
    </CounterText>
  );
};

export default Home;
