import { useEffect, useState } from "react";
import { Routes, Route, Link } from "react-router-dom";

import styled from "styled-components";
import Countdown from "react-countdown";
import { Button, CircularProgress, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import dadHead from "./images/dadHead.png";
import logo from "./images/raddadsLogo.png";


import * as anchor from "@project-serum/anchor";

import { LAMPORTS_PER_SOL } from "@solana/web3.js";

import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";

import {
  CandyMachine,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
  shortenAddress,
} from "./candy-machine";

const ConnectButton = styled(WalletDialogButton)`
  color: white;
  background-color: #272626;
  font-weight: bold;

  &:hover {
    color: white;
    background-color: teal;
    font-weight: bold;
  }
`;

const CounterText = styled.span``; // add your styles here

const MintContainer = styled.div``; // add your styles here

const MintButton = styled(Button)`
  color:rgb(69, 34, 181);
  background-color: white;
  font-weight: bold;
  &:hover {
    color: white;
    background-color: teal;
    font-weight: bold;
  }
`; // add your styles here

export interface HomeProps {
  candyMachineId: anchor.web3.PublicKey;
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
}

const Home = (props: HomeProps) => {
  const [balance, setBalance] = useState<number>();
  const [isActive, setIsActive] = useState(false); // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT

  const [itemsAvailable, setItemsAvailable] = useState(0);
  const [itemsRedeemed, setItemsRedeemed] = useState(0);
  const [itemsRemaining, setItemsRemaining] = useState(0);

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const [startDate, setStartDate] = useState(new Date(props.startDate));

  const wallet = useAnchorWallet();
  const [candyMachine, setCandyMachine] = useState<CandyMachine>();

  const refreshCandyMachineState = () => {
    (async () => {
      if (!wallet) return;

      const {
        candyMachine,
        goLiveDate,
        itemsAvailable,
        itemsRemaining,
        itemsRedeemed,
      } = await getCandyMachineState(
        wallet as anchor.Wallet,
        props.candyMachineId,
        props.connection
      );

      setItemsAvailable(itemsAvailable);
      setItemsRemaining(itemsRemaining);
      setItemsRedeemed(itemsRedeemed);

      setIsSoldOut(itemsRemaining === 0);
      setStartDate(goLiveDate);
      setCandyMachine(candyMachine);
    })();
  };

  const onMint = async () => {
    try {
      setIsMinting(true);
      if (wallet && candyMachine?.program) {
        const mintTxId = await mintOneToken(
          candyMachine,
          props.config,
          wallet.publicKey,
          props.treasury
        );

        const status = await awaitTransactionSignatureConfirmation(
          mintTxId,
          props.txTimeout,
          props.connection,
          "singleGossip",
          false
        );

        if (!status?.err) {
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success",
          });
        } else {
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          });
        }
      }
    } catch (error: any) {
      // TODO: blech:
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
    } finally {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
      setIsMinting(false);
      refreshCandyMachineState();
    }
  };

  useEffect(() => {
    (async () => {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet, props.connection]);

  useEffect(refreshCandyMachineState, [
    wallet,
    props.candyMachineId,
    props.connection,
  ]);

  return (
    <main className="main-container-how-to">
      <div className="top">
          <div>
            {wallet ? (
              <div className="wallet-info-container">

              <div className="wallet-info">
                <text><b>Your Wallet:</b> {shortenAddress(wallet.publicKey.toBase58() || "")}</text>
                <text><b>Sol Available:</b> {(balance || 0).toLocaleString()}◎</text>
              </div>
              </div>
            ) : (
              <ConnectButton>Connect Wallet</ConnectButton>
            )
            }
          </div>
          <div className="menu-items">
            <div className="menu-item">
              <Link to="/" className="menu-item-text">Home</Link>
            </div>
            <div className="menu-item">
              <Link to="/howto" className="menu-item-text">How to Mint</Link>
            </div>
            <div className="menu-item">
              <Link to="/team" className="menu-item-text">Team</Link>

            </div>
          </div>
      </div>

      <div className="how-to-container">

        <div className="how-to-body">
          <div className="about-item-logo">
            <div className="about-top">
            <img src={dadHead} alt="dad head"/>

              <h1 className="how-to-title">About & Roadmap</h1>
              {/* <div className="how-to-logo">
                <img src={logo} alt="logo"/>
              </div> */}
            </div>
          </div>
          <div className="how-to-item">
            <div className="how-to-item-info">
              <h1>Hi Minting,<br></br>I'm Dad 👴</h1>
              <text>We're putting 4200 Dads on Solana, and they're rad as hell.</text>
              <text>Rad Dads NFT is the Metaverse wing of <a href={'https://www.raddadsofficial.com'} className="how-to-link">Rad Dads (official)</a>, a menswear brand and lifestyle movement that celebrates and respects Dads.</text>
              <text>Rad Dads sponsors post-collegiate sports teams across the country.</text>
              <text>Pundits have said that our lacrosse team is the best non-professional squad in the world. The Dads have claimed NYC titles in 2016, 2018 and 2021 as well as Boulder, CO and San Francisco, CA titles in 2019.</text>

              <text>We are the first NFT project with a dedicated amateur sports team. We will play to bring glory to our holders, who can vote on logos, uniforms and more.</text>
            </div>
            <div className="how-to-item-info">
              <h1>Rights to<br></br>your Dad 📃</h1>
              <text>You own your Dad <b>completely</b>. When you purchase a
              Rad Dads NFT, you own the underlying Rad Dad and the Art.</text>
              <text>You have a worldwide, royalty-free license to use, copy and display your purchased Dad while you own it.</text>
              <text>You also own the right to create derivative works based upon your Dad (“Commercial Use”) however you see fit.</text>
              <text>We may use your Dad for promotional purposes, but we will never sell items featuring your Dad without your consent.</text>

            </div>
          </div>

          <div className="how-to-item">
            <div className="how-to-item-info">
              <h1>Q1 2022 🗓</h1>

              <text>Verify collection on major Solana NFT marketplaces.</text>
              <text>Provide Rarity Sniper and HowRare.is integration for trait analysis.</text>
              <text>Launch webstore where holders can get exclusive 1/1 pieces featuring their Dad.</text>
              <text>Create Discord games to give holders more ways to earn rewards.</text>
              <text>Sponsor community contests where holders can win special airdrops, merch, 1/1s and other prizes.</text>



            </div>
            <div className="how-to-item-info">
              <h1>Q2 2022 🗓</h1>
              <text>Launch <b>Dad Battler</b>, where holders can once and for all settle the "My Dad can beat up your Dad" debate.</text>
              <text>Create <b>DadDao</b> for community-wide voting and action.</text>
              <text>Offer access to future mints, DadDao and webstore rewards based on community interaction, contests, Dad Battler and Discord game performance.</text>

            </div>
          </div>

        </div>

      </div>
    </main>
  );
};

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}

const renderCounter = ({ days, hours, minutes, seconds, completed }: any) => {
  return (
    <CounterText>
      {hours + (days || 0) * 24} hours, {minutes} minutes, {seconds} seconds
    </CounterText>
  );
};

export default Home;

