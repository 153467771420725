import { useEffect, useState } from "react";
import { Routes, Route, Link } from "react-router-dom";

import styled from "styled-components";
import Countdown from "react-countdown";
import { Button, CircularProgress, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import dadHead from "./images/dadHead.png";
import logo from "./images/raddadsLogo.png";


import * as anchor from "@project-serum/anchor";

import { LAMPORTS_PER_SOL } from "@solana/web3.js";

import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";

import {
  CandyMachine,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
  shortenAddress,
} from "./candy-machine";

const ConnectButton = styled(WalletDialogButton)`
  color: white;
  background-color: #272626;
  font-weight: bold;

  &:hover {
    color: white;
    background-color: teal;
    font-weight: bold;
  }
`;

const CounterText = styled.span``; // add your styles here

const MintContainer = styled.div``; // add your styles here

const MintButton = styled(Button)`
  color:rgb(69, 34, 181);
  background-color: white;
  font-weight: bold;
  &:hover {
    color: white;
    background-color: teal;
    font-weight: bold;
  }
`; // add your styles here

export interface HomeProps {
  candyMachineId: anchor.web3.PublicKey;
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
}

const Home = (props: HomeProps) => {
  const [balance, setBalance] = useState<number>();
  const [isActive, setIsActive] = useState(false); // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT

  const [itemsAvailable, setItemsAvailable] = useState(0);
  const [itemsRedeemed, setItemsRedeemed] = useState(0);
  const [itemsRemaining, setItemsRemaining] = useState(0);

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const [startDate, setStartDate] = useState(new Date(props.startDate));

  const wallet = useAnchorWallet();
  const [candyMachine, setCandyMachine] = useState<CandyMachine>();

  const refreshCandyMachineState = () => {
    (async () => {
      if (!wallet) return;

      const {
        candyMachine,
        goLiveDate,
        itemsAvailable,
        itemsRemaining,
        itemsRedeemed,
      } = await getCandyMachineState(
        wallet as anchor.Wallet,
        props.candyMachineId,
        props.connection
      );

      setItemsAvailable(itemsAvailable);
      setItemsRemaining(itemsRemaining);
      setItemsRedeemed(itemsRedeemed);

      setIsSoldOut(itemsRemaining === 0);
      setStartDate(goLiveDate);
      setCandyMachine(candyMachine);
    })();
  };

  const onMint = async () => {
    try {
      setIsMinting(true);
      if (wallet && candyMachine?.program) {
        const mintTxId = await mintOneToken(
          candyMachine,
          props.config,
          wallet.publicKey,
          props.treasury
        );

        const status = await awaitTransactionSignatureConfirmation(
          mintTxId,
          props.txTimeout,
          props.connection,
          "singleGossip",
          false
        );

        if (!status?.err) {
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success",
          });
        } else {
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          });
        }
      }
    } catch (error: any) {
      // TODO: blech:
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
    } finally {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
      setIsMinting(false);
      refreshCandyMachineState();
    }
  };

  useEffect(() => {
    (async () => {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet, props.connection]);

  useEffect(refreshCandyMachineState, [
    wallet,
    props.candyMachineId,
    props.connection,
  ]);

  return (
    <main className="main-container-how-to">
      <div className="top">
          <div>
            {wallet ? (
              <div className="wallet-info-container">
              <div className="wallet-info">
                <text><b>Your Wallet:</b> {shortenAddress(wallet.publicKey.toBase58() || "")}</text>
                <text><b>Sol Available:</b> {(balance || 0).toLocaleString()}◎</text>
              </div>
              </div>
            ) : (
              <ConnectButton>Connect Wallet</ConnectButton>
            )
            }
          </div>
          <div className="menu-items">
            <div className="menu-item">
              <Link to="/" className="menu-item-text">Home</Link>
            </div>
            <div className="menu-item">
              <Link to="/about" className="menu-item-text">About/Roadmap</Link>
            </div>
            <div className="menu-item">
              <Link to="/team" className="menu-item-text">Team</Link>

            </div>
          </div>
      </div>

      <div className="how-to-container">

        <div className="how-to-body">
          <div className="how-to-item-logo">
            <div className="how-to-top">
            <img src={logo} alt="logo"/>
              <h1 className="how-to-title">How to mint a Dad</h1>
              {/* <div className="how-to-logo">
                <img src={logo} alt="logo"/>
              </div> */}
            </div>
          </div>
          <div className="how-to-item">
            <div className="how-to-item-info">
              <h1>1. Buy SOL 💳</h1>
              <text>We're minting on Solana, so you'll need SOL to mint your Dad.</text>
              <br></br>
              <text>You can purchase SOL with USD or other currencies on several exchanges, including <a href={'https://www.coinbase.com/join/czerny_p7'} className="how-to-link">Coinbase</a>, <a href={'https://ftx.us/home/#a=20042345'} className="how-to-link">FTX.US</a>, <a href={'https://accounts.binance.com/en/register?ref=10991900'} className="how-to-link">Binance</a> and <a href={'https://ftx.com/referrals#a=20063762'} className="how-to-link">FTX</a>.</text>
              <br></br>

              <text>Click on any link above to create an accout. You'll use the SOL you buy to fund your minting wallet.</text>
            </div>
            <div className="how-to-item-info">
              <h1>2. Download<br></br>a wallet 💻</h1>
              <text>Download a web wallet to use for this mint, we recommend using <a href={'https://phantom.app/'} className="how-to-link">Phantom</a> or <a href={'https://solflare.com/'} className="how-to-link">SolFlare</a>.</text>
              <br></br>

              <text>You can click either link above to download your wallet.</text>
              <br></br>
              <text>Make sure you install the wallet's browser extension too.</text>

            </div>
          </div>

          <div className="how-to-item">
            <div className="how-to-item-info">
              <h1>3. Send SOL to<br></br>your wallet 💸</h1>

              <text>You'll have to transfer your SOL from your exchange wallet to your web wallet to mint.</text>
              <br></br>
              <text>First, copy your wallet address from your web wallet.</text>
              <text>Send SOL from your exchange wallet to that copied address.</text>
              <br></br>
              <text>If you have a hardware wallet linked to your web wallet click <b>Allow Blind Signing</b> in the Solana app settings.</text>
            </div>

            <div className="how-to-item-info">
              <h1>4. Connect<br></br>and mint 🔗</h1>

              <text>Click the <b>Connect Wallet</b> button at the top of the page to connect your wallet to this website for the mint.</text>
              <text>Select your web wallet and follow the instructions. Once your wallet is connected you'll be good to go.</text>
              <text>Click <b>Mint a Dad</b> to secure your new rad father. You'll have to pay a small gas fee to mint.</text>
              <text>You can see your new Dad in the NFT tab of your web wallet.</text>
            </div>
          </div>

        </div>

      </div>
    </main>
  );
};

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}

const renderCounter = ({ days, hours, minutes, seconds, completed }: any) => {
  return (
    <CounterText>
      {hours + (days || 0) * 24} hours, {minutes} minutes, {seconds} seconds
    </CounterText>
  );
};

export default Home;
